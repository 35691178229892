import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import './style.css';
import Interactable from "./Drag";
import { Chip } from '@material-ui/core';




const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: '5px',
    backgroundColor: '#fefefe',
    height: '100%'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  field: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: '650px',
    backgroundColor: '#f5f7fe'
  },
  header:{
    backgroundColor: '#3174ff',
    color: '#ffffff',
    textAlign: 'center',
    height: '30px',
    marginBottom: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px'
  }
}));

//3174ff

const draggableOptions = {
  onmove: event => {
    const target = event.target;
    // keep the dragged position in the data-x/data-y attributes
    const x = (parseFloat(target.getAttribute("data-x")) || 0) + event.dx;
    const y = (parseFloat(target.getAttribute("data-y")) || 0) + event.dy;

    // translate the element
    target.style.webkitTransform = target.style.transform =
      "translate(" + x + "px, " + y + "px)";

    // update the posiion attributes
    target.setAttribute("data-x", x);
    target.setAttribute("data-y", y);
  }
};


export default function App() {
  const classes = useStyles();
  const [width, setwidth] = useState(50);
  const [height, setheight] = useState(50);

  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #ddd",
    background: "#f0f0f0"
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.header}>DESIGN@LITTLE</Paper>
        </Grid>
        <Grid item xs={6} >

          <Paper className={classes.field} style={{backgroundColor: "#A9A9A9", color: "#ffffff"}}>
            Components
          <Interactable draggable={true} draggableOptions={draggableOptions}>
              <div className="draggable drag-item">
              <Chip
                
                label="Move me"
               
              />
              </div>
          </Interactable>

          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.field}>
          <div className="smartphone">
          <div>
            <div>
              Drag Here
            </div>
            <Interactable
          dropzone={true}
          dropzoneOptions={{
            // only accept elements matching this CSS selector
            accept: ".drag-item",
            // Require a 75% element overlap for a drop to be possible
            overlap: 0.75,

            // listen for drop related events:

            ondropactivate: function(event) {
              // add active dropzone feedback
              event.target.classList.add("drop-active");
            },

            ondragenter: function(event) {
              var draggableElement = event.relatedTarget,
                dropzoneElement = event.target;

              // feedback the possibility of a drop
              dropzoneElement.classList.add("drop-target");
              draggableElement.classList.add("can-drop");
              draggableElement.textContent = "Dragged in";
            },

            ondragleave: function(event) {
              // remove the drop feedback style
              event.target.classList.remove("drop-target");
              event.relatedTarget.classList.remove("can-drop");
              event.relatedTarget.textContent = "Dragged out";
            },

            ondrop: function(event) {
              event.relatedTarget.textContent = "Dropped";
            },

            ondropdeactivate: function(event) {
              // remove active dropzone feedback
              event.target.classList.remove("drop-active");
              event.target.classList.remove("drop-target");
            }
          }}
        >
          <div className="dropzone" id="outer-dropzone">
           
          </div>
        </Interactable>
          </div>
          </div>
            </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

